import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { ConfigLoader } from './components/ConfigLoader';
import { Authenticate } from './components/Authenticate';
import JobDetails from './components/JobDetails';
import JobsOverview from './components/JobsOverview';
import ClusterDetails from './components/ClusterDetails';
import Clusters from './components/Clusters';
import Statistics from './components/Statistics';
import SubmitJob from './components/SubmitJob';
import CopyToken from './components/CopyToken';
import InvalidJobsFromBatch from './components/InvalidJobsFromPools';
import ClearDLQMessages from './components/DLQMessageCleanUp';
import { styled } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { Stack } from '@mui/material';
import DrawerList from './components/DrawerList';
import theme from './utils/Theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AppHeader from './components/AppHeader';

const StyledDivRoot = styled('div')(() => ({
    flexGrow: 1,
}))

const StyledDivContent = styled('div')(() => ({
    flexGrow: 1,
    height: '100%',
    width: "80%"
}))

const StyledDivContentRoot = styled('div')(() => ({
    display: 'flex',
    width: '100%',
}))

export default class App extends Component {
  render() {
      return (
          <ThemeProvider theme={theme}>
          <ConfigLoader>
            <Authenticate>
              <SnackbarProvider maxSnack={3}>
                <StyledEngineProvider injectFirst>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <BrowserRouter >
                     <CssBaseline />
                      <StyledDivRoot >
                        <Stack>
                          <AppHeader />
                          <Stack direction="row" >
                            <StyledDivContentRoot>
                                <DrawerList />
                                <StyledDivContent>
                                    <Routes>
                                    <Route path='/' element={<Navigate to="/admin/dashboard" />} />
                                    <Route path='/admin' element={<Navigate to="/admin/dashboard" />} />                                   
                                    <Route path='/admin/dashboard' element={<Dashboard />} />
                                    <Route path="/admin/jobs/:jobId" element={<JobDetails />} />
                                    <Route path='/admin/jobs' element={<JobsOverview  {...{ pageSize: 20, pageSizeOptions: [10, 20, 30] }} />} />
                                    <Route path="/admin/clusters/:location/:name" element={<ClusterDetails/>} />
                                    <Route path='/admin/clusters' element={<Clusters/>} />
                                    <Route path='/admin/statistics' element={<Statistics/>} />
                                    <Route path='/admin/tools/copytoken' element={<CopyToken/>} />
                                    <Route path='/admin/tools/submitjob' element={<SubmitJob/>} />
                                    <Route path='/admin/azureResources/invalidjobsfrompools' element={<InvalidJobsFromBatch/>} />
                                    <Route path='/admin/azureResources/dlqmessages' element={<ClearDLQMessages/>} />
                                    </Routes>
                                </StyledDivContent>
                            </StyledDivContentRoot>
                          </Stack>
                        </Stack>
                      </StyledDivRoot> 
                    </BrowserRouter>
                  </LocalizationProvider>  
                </StyledEngineProvider>
              </SnackbarProvider>
            </Authenticate>
          </ConfigLoader>
        </ThemeProvider>
    );
  }
}





